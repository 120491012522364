import React, { useState } from "react";
import { Link } from "react-router-dom";
import { WhatsappShareButton, FacebookShareButton } from "react-share";
import logo from "../assets/img/Group3.png";
import car from "../assets/img/MaskGroup2@2x.png";
import WhatsappIcon from "../assets/img/WhatsappIcon.svg";
import facebookIcon from "../assets/img/facebookIcon.svg";
import "../assets/css/SharePage.css";
import TagManager from "react-gtm-module";

export const SharePage = () => {
  const [shareMessage, setShareMessage] = useState("אוראו במולטיוורס");
  const [copied, setCopied] = useState(false);
  function copy() {
    TagManager.dataLayer({
      dataLayer: { event: "menu_share_link" },
    });
    const el = document.createElement("input");
    el.value = window.location.hostname;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  return (
    <div
      className="ac-share-page"
      style={{
        backgroundImage: `url(${
         "https://oreo.activated.digital/img/startgameBg.jpg"
        })`,
        backgroundPosition: "center",
        backgroundRepeat: "noRepeat",
        backgroundSize: "100%",
      }}
    >
      <div className="ac-container">
        {/* <div className="bread">
          <Link
            to="/"
            onClick={() => {
              window.location.replace("https://oreo.activated.digital/");
              TagManager.dataLayer({ dataLayer: { event: "homepage" } });
            }}
          >
            בית{" "}
          </Link>{" "}
          {" > "}
          <Link
            to="/SharePage"
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "true_friend" } });
            }}
          >
            חבר אמיתי משתף
          </Link>
        </div> */}
        <div className="ac-img-wrapper">
          <img src={logo} alt="Logo" className="ac-img-responive" />
        </div>
        {/* <p>ההודעה שלך:</p> */}
        {/* <div className="ac-sharemesssage">
          <div className="ac-img-wrapper">
            <img src={logo} alt="Logo" className="ac-img-responive" />
          </div>
          <div className="ac-message-input">
            {/*<label>The share message</label>*/}
        {/* <textArea
              className="ac-input"
              type="text"
              value={shareMessage}
              placeholder='מי בא לחו"ל??? התחלתי לשחק בפעילות של אוראו כי בא לי לטוס מפה! גם לך בא לזכות בכרטיס פתוח לשנה לחופשות זוגיות בעולם? אז היידה למשחק.'
              onChange={(e) => setShareMessage(e.target.value)}
            />
          </div>
        </div> */}
        <span class="ac-sharepage-text">
          בעוגיית אוראו לא משתפים חברים,
          <br />
          <strong>
            במשחק אוראו שאפשר
            <br />
            לזכות בו במיני קופר <br />
            משתפים במקסימום!
          </strong>
        </span>

        <div className="ac-wrapper-btns">
          <FacebookShareButton
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: { event: "menu_share_facebook" },
              });
            }}
            url={"https://oreo.activated.digital/"}
            title={"אוראו במולטיוורס"}
            quote={shareMessage}
          >
            <span>שיתוף ב-</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.924"
              height="27.755"
              viewBox="0 0 27.924 27.755"
            >
              <g data-name="Group 421" transform="translate(27.924 13.962)">
                <path
                  fill="#0057c8"
                  d="M0 0a13.962 13.962 0 00-13.962-13.962A13.962 13.962 0 00-27.924 0a13.966 13.966 0 0011.78 13.793V4.036h-3.545V0h3.545v-3.076c0-3.5 2.085-5.432 5.274-5.432a21.508 21.508 0 013.125.272V-4.8h-1.76a2.019 2.019 0 00-2.276 2.181V0h3.873l-.619 4.036h-3.254v9.757A13.967 13.967 0 000 0"
                  data-name="Path 242"
                ></path>
              </g>
            </svg>
          </FacebookShareButton>
          <WhatsappShareButton
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: { event: "menu_share_whatsapp" },
              });
            }}
            url={`${shareMessage} ${"https://oreo.activated.digital/"}`}
            // title={shareMessage}
          >
            <span>שיתוף ב-</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26.15"
              height="26.275"
              viewBox="0 0 26.15 26.275"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    fill="none"
                    d="M101.845-70.744H128v-26.275h-26.15z"
                    data-name="Path 246"
                  ></path>
                </clipPath>
              </defs>
              <g
                clipPath="url(#clip-path)"
                data-name="Group 425"
                transform="translate(-101.845 97.019)"
              >
                <g data-name="Group 424" transform="translate(120.91 -81.273)">
                  <path
                    fill="#004fa2"
                    fillRule="evenodd"
                    d="M0 0c-.325-.163-1.925-.95-2.223-1.058s-.515-.163-.732.162S-3.8.163-3.985.38s-.379.244-.705.081a8.874 8.874 0 01-2.616-1.614 9.818 9.818 0 01-1.81-2.254c-.189-.326-.02-.5.143-.664.146-.146.325-.38.488-.57a2.192 2.192 0 00.325-.542.6.6 0 00-.027-.57c-.081-.163-.732-1.764-1-2.416-.264-.634-.532-.548-.732-.558s-.406-.012-.623-.012a1.2 1.2 0 00-.868.407 3.651 3.651 0 00-1.138 2.714 6.321 6.321 0 001.328 3.365A14.505 14.505 0 00-5.666 2.66a18.686 18.686 0 001.855.685 4.45 4.45 0 002.049.129 3.352 3.352 0 002.2-1.547A2.711 2.711 0 00.623.38C.542.244.325.163 0 0m-5.936 8.1a10.809 10.809 0 01-5.512-1.5l-.4-.234-4.1 1.074 1.094-3.993-.258-.41a10.793 10.793 0 01-1.654-5.759A10.837 10.837 0 01-5.931-13.546a10.745 10.745 0 017.65 3.174 10.756 10.756 0 013.167 7.655A10.838 10.838 0 01-5.936 8.1m9.21-20.032a12.937 12.937 0 00-9.21-3.818 13.038 13.038 0 00-13.02 13.024 13.013 13.013 0 001.738 6.509l-1.847 6.746 6.9-1.81A13 13 0 00-5.941 10.3h.005A13.038 13.038 0 007.084-2.716a12.943 12.943 0 00-3.81-9.211"
                    data-name="Path 245"
                  ></path>
                </g>
              </g>
            </svg>
          </WhatsappShareButton>
          <button onClick={copy}>
            {!copied ? "העתקת קישור למשחק" : "העתקת"}
          </button>
          <div className="ac-img-wrapper ac-car">
            <img src={car} alt="car" className="ac-img-responive" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePage;
