import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import VerifNumber from "../Components/Popup/VerifNumber";
import SendSms from "../Components/Popup/SendSms";
import EndSend from "./Popup/EndSend";
import ThanksPopup from "./Popup/Thanks";
import RecipePopup from "./Popup/RecipePopup";
// import SendPicture from "../Components/Popup/SendPicture";
import { ServerTime } from "../Components/Utility";
import "react-html5-camera-photo/build/css/index.css";
import { Leads } from "../Config/Lead";
import "../assets/css/checkbox.css";
// import { encrypt, getKey, isNumberKey, milis } from "../Config/Utils";
import { getKey, isNumberKey, milis } from "../Config/Utils";
import TagManager from "react-gtm-module";
import pdf from "../assets/takanon.pdf";
import { render } from "react-dom";


const FormFinal = (props) => {
  // const {
  //   register,
  //   formState: { errors },
  //   handleSubmit,
  // } = useForm();
  // console.log("PROPS FORM:", props);
  const [data, setData] = useState({});
  const [url, setUrl] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedTakanon, setCheckedTakanon] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [SendPictureState, setSendPictureState] = useState(true);
  const [SentSms, setSentSms] = useState(false);
  const [isEnd, setisEnd] = useState(false);
  // const [isIncorrentNum, setIsIncorrentNum] = useState(true);
  const [addImgPopup, setAddImgPopup] = useState(false);
  const [isSave, SetISave] = useState(false);
  const [saveWitthsms, setsaveWithsms] = useState(false);
  const [keyClient] = useState(getKey());
  const [saveIPstate, setSaveIpState] = useState(true);
  const [IP, setIp] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberPopup, setMobileNumberPopup] = useState("");
  const [imgVal, setimgVal] = useState("");
  const [message, setMessage] = useState("");
  const [popupRecipe, setPopupRecipe] = useState(false);
  const [thanksPopup, setThanksPopup] = useState(false);
  const [isGotSms, setIsGotSms] = useState(false);
  const [closeSmsPopup, setCloseSmsPopup] = useState(false);
  const [serverEndClock, setServerEndClock] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [acTime, setAcTime] = useState(null);
  const [miliseconds, setMiliseconds] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    var $serverStartClock = props?.serverStart;
    $serverStartClock = $serverStartClock.substring(11, 19);
    var $serverStartClock1 = $serverStartClock.replace(":", "");
    $serverStartClock1 = $serverStartClock1.replace(":", "");

    var $getHoursSt = Number($serverStartClock1.substring(0, 2));
    // console.log("$getHoursSt: ", $getHoursSt); //13

    var $getHourInSecSt = $getHoursSt * 3600;
    // console.log("$getHourInSecSt: ", $getHourInSecSt); //2700

    var $getMinSecSt = $serverStartClock1.substring(2, 6);
    // console.log("$getMinSecSt: ", $getMinSecSt); //4533

    var $getMinSt = Number($getMinSecSt.substring(0, 2));
    // console.log("$getMinSt: ", $getMinSt); //45

    $getMinSt = $getMinSt * 60;
    // console.log("$getMinSt: ", $getMinSt); //2700

    var $getSecSt = Number($getMinSecSt.substring(2, 4));
    // console.log("$getSecSt: ", $getSecSt); //33

    var $totalSecMinSt = $getHourInSecSt + $getMinSt + $getSecSt;
    // console.log("$totalSecMinSt", $totalSecMinSt);

    // var StartDate = props?.serverStart.substring(2, 10);
    // StartDate = StartDate.replace("-", "");
    // StartDate = StartDate.replace("-", "");
    // console.log(StartDate);

    var $serverEndClock = serverEndClock;
    $serverEndClock = $serverEndClock.substring(11, 19);
    var $serverEndClock1 = $serverEndClock.replace(":", "");
    $serverEndClock1 = $serverEndClock1.replace(":", "");
    // console.log("$serverEndClock1: ", $serverEndClock1);

    var $getHoursEnd = Number($serverEndClock1.substring(0, 2));
    // console.log("$getHoursEnd: ", $getHoursEnd); //13

    var $getHourInSecEnd = $getHoursEnd * 3600;
    // console.log("$getHourInSecSt: ", $getHourInSecSt); //2700

    var $getMinSecEnd = $serverEndClock1.substring(2, 6);
    // console.log("$getMinSecEnd: ", $getMinSecEnd); //4533

    var $getMinEnd = Number($getMinSecEnd.substring(0, 2));
    // console.log("$getMinEnd: ", $getMinEnd); //45

    $getMinEnd = $getMinEnd * 60;
    // console.log("$getMinEnd: ", $getMinEnd); //2700

    var $getSecEnd = Number($getMinSecEnd.substring(2, 4));
    // console.log("$getSecEnd: ", $getSecEnd); //33

    var $totalSecMinEnd = $getHourInSecEnd + $getMinEnd + $getSecEnd;
    // console.log("$totalSecMinEnd", $totalSecMinEnd);

    // var EndDate = serverEndClock.substring(2, 10);
    // EndDate = EndDate.replace("-", "");
    // EndDate = EndDate.replace("-", "");
    // console.log(EndDate);

    const totalSeconds = $totalSecMinEnd - $totalSecMinSt;
    // console.log("totalSeconds: ", totalSeconds);
    // get number of full minutes
    const minutes = Math.floor(totalSeconds / 60);

    // 👇️ get remainder of seconds
    const seconds = totalSeconds % 60;

    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }

    // ✅ format as MM:SS
    const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    // console.log(result); // 👉️ "09:25"

    const $time = props?.time;
    const $isDev = props?.isDev;
    var $timeSectionId = props?.time;
    var $hash = (+new Date()).toString(36);

    $timeSectionId = $timeSectionId.replace(":", "");
    $timeSectionId = $timeSectionId.replace(":", "");
    var sectionId =
      $timeSectionId +
      keyClient +
      $hash +
      $serverStartClock1 +
      $serverEndClock1;
    // console.log($timeSectionId);
    // console.log(sectionId);
    const $values = {
      text_1: name,
      email_2: email,
      text_2: mobileNumber,
      text_3: $time,
      text_4: keyClient,
      text_5: $isDev ? "true" : "false",
      text_6: IP,
      hidden_1: sectionId,
      hidden_2: acTime,
    };

    // console.log("humanDiff:", humanDiff);
    // const enc = encrypt(JSON.stringify($values));
    if (checked == true && checkedTakanon == true) {
      try {
        let res = await fetch(
          "https://crud.activated.digital/app/app/f?id=13",
          {
            method: "POST",
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify($values),
          }
        );
        let resJson = await res.json();
        if (res.status === 200) {
          TagManager.dataLayer({
            dataLayer: { event: "form_submitt" },
          });
          setName("");
          setEmail("");
          // setMessage("User created successfully");
          setAddImgPopup(true);
        } else {
          setMessage("Some error occured");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("אנה סמן שני צ׳קבוקסים ");
    }
  };
  const handleSubmitSMS = async (e) => {
    // console.log("DATA:", data);
    e.preventDefault();
    const dataSend = {
      phone: mobileNumber,
    };

    try {
      let res = await fetch("https://oreo.activated.digital/sendSMS2.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(dataSend),
      });
      setIsGotSms(true);
      setThanksPopup(false);
      let resJson = await res.json();
      if (res.status === 200) {
        console.log("Sent");
      } else {
        console.log("Not Sent");
      }
    } catch (err) {
      console.log(err);
    }
  };
  // Upload the img
  // const handleSubmit2 = async (e) => {
  //   e.preventDefault();

  //   const $values = {
  //     text_1: mobileNumberPopup,
  //     // file_1: imgVal,
  //   };
  //   const enc = encrypt(JSON.stringify($values));

  //   try {
  //     let res = await fetch("https://crud.activated.digital/app/app/f?id=15", {
  //       method: "POST",
  //       timeout: 0,
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       body: JSON.stringify($values),
  //     });
  //     let resJson = await res.json();
  //     if (res.status === 200) {
  //       setName("");
  //       setEmail("");
  //       // setThanksPopup(true);
  //       window.location.replace("https://oreo.activated.digital/thankyou");
  //       setAddImgPopup(false);
  //       // setMessage("User created successfully");
  //     } else {
  //       setMessage("Some error occured");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   if (url !== "") {
  //     if (!isSave) {
  //       SetISave(true);
  //       saveDataWithPicture();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [url]);

  // useEffect(() => {
  //   if (saveWitthsms) {
  //     if (!isSave) {
  //       SetISave(true);
  //       saveDataWithoutPicture();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [saveWitthsms]);

  useEffect(() => {
    if (serverEndClock == null || serverEndClock == undefined) {
      setServerEndClock(ServerTime);
      if (serverEndClock !== null || serverEndClock !== undefined) {
        setIsLoad(true);
      }
    }

    // console.log(serverEndClock);

    const getData = async () => {
      const response = await fetch("https://geolocation-db.com/json/");
      const data = await response.json();
      setIp(data.IPv4);
      setSaveIpState(false);
    };

    if (saveIPstate) {
      getData();
    }

    if (isLoad) {
      const date1 = new Date(props?.serverStart.replace(/-/g, "/"));
      const date2 = new Date(serverEndClock.replace(/-/g, "/"));
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setMiliseconds(diffTime);
      // console.log(diffTime + " milliseconds");
      // console.log(diffDays + " days");
      if (miliseconds !== undefined) {
        function msToTime(s) {
          // Pad to 2 or 3 digits, default is 2
          function pad(n, z) {
            z = z || 2;
            return ("00" + n).slice(-z);
          }

          var ms = s % 1000;
          s = (s - ms) / 1000;
          var secs = s % 60;
          s = (s - secs) / 60;
          var mins = s % 60;
          var hrs = (s - mins) / 60;
          // return pad(mins) + ":" + pad(secs) + ":" + pad(ms);
          return pad(mins) + ":" + pad(secs) + ":" + milis();
        }

        setAcTime(msToTime(miliseconds));
        // console.log(miliseconds);
        // console.log(acTime);
      }
    }
  });
  const helpme = (e) => {
    if (!isNumberKey(e)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label for="ac-name">שם:</label>
        <input
          id="ac-name"
          type="text"
          value={name}
          placeholder="שם"
          required
          onChange={(e) => setName(e.target.value)}
        />
        <label for="ac-email">דוא"ל:</label>
        <input
          type="text"
          value={email}
          placeholder='דוא"ל'
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <label for="ac-phone">נייד:</label>
        <input
          type="text"
          value={mobileNumber}
          placeholder="נייד"
          required
          maxLength={"10"}
          onChange={(e) => setMobileNumber(e.target.value)}
        />

        <button type="submit">
          <span>לשלב אחרון חביב</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.167"
            height="21.168"
            viewBox="0 0 21.167 21.168"
          >
            <g data-name="Group 32" transform="translate(0 10.584)">
              <path
                fill="#0057c8"
                d="M0 0l21.167 10.584v-21.168z"
                data-name="Path 17"
              ></path>
            </g>
          </svg>
        </button>

        <div className="message">{message ? <p>{message}</p> : null}</div>
        <div className="ac-checkboxs">
          <label for="ac-checkbox-1">
            ידוע לי שכדי לזכות צריך צילום חשבונית בשווי ₪25 על מגוון עוגיות
            אוראו
          </label>
          <input
            id="ac-checkbox-1"
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              setChecked(true);
            }}
          />
        </div>
        <div className="ac-checkboxs">
          <label for="ac-checkbox-2">
            קראתי את{" "}
            <a
              href={pdf}
              target="_blank"
              style={{ textDecoration: "underline", color: "#fff" }}
            >
              התקנון
            </a>
          </label>
          <input
            id="ac-checkbox-2"
            type="checkbox"
            checked={checkedTakanon}
            onChange={(e) => {
              setCheckedTakanon(true);
            }}
          />
        </div>
      </form>
      {/* {!SendPictureState ? (
        <SendPicture
          setUrl={setUrl}
          stateSMS={setSentSms}
          closepict={setSendPictureState}
        />
      ) : null} */}
      {/* {isEnd ? <EndSend /> : null} */}
      {/* {SentSms ? (
        <SendSms
          CKeyFDB={keyClient}
          phoneNumber={phoneNumber}
          desactivate={setSentSms}
          sendtoend={setisEnd}
          setisincorrect={setIsIncorrentNum}
          setsave={setsaveWithsms}
        />
      ) : null}{" "}
      {!isIncorrentNum ? (
        <VerifNumber
          unable={setIsIncorrentNum}
          sendSms={setSentSms}
          phoneNumber={phoneNumber}
          setphone={setPhoneNumber}
        />
      ) : null} */}

      {/* Upload Image Pop up*/}
      {addImgPopup ? (
        <div className="ac-addimgpopup">
          <span className="ac-addimgpopup-ttl">
            כדי לזכות <br /> יש לצרף חשבונית
          </span>
          {/* <form class="ac-form2" onSubmit={handleSubmit2}>
            <input
              type="text"
              value={mobileNumberPopup}
              placeholder="מספר טלפון"
              required
              onChange={(e) => setMobileNumberPopup(e.target.value)}
            />
            <input
              type="file"
              id="file_1"
              name="file_1[]"
              data-alias=""
              accept=".gif, .jpg, .png"
              value={imgVal}
              onChange={(e) => setimgVal(e.target.value)}
            />
            <button type="submit">שליחה</button>
          </form> */}
          <form
            action="https://crud.activated.digital/app/app/f?id=15"
            method="post"
            enctype="multipart/form-data"
            accept-charset="UTF-8"
            id="form-app"
          >
            {/* <input
                    type="tel"
                    id="text_1"
                    name="text_1"
                    value=""
                    data-alias=""
                    class="form-control"
                    placeholder="טלפון"
                  />*/}
            {/* <form class="ac-form2" onSubmit={handleSubmit2}> */}
            <fieldset class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <label class="control-label" for="text_1">
                    נייד
                  </label>
                  <input
                    type="tel"
                    id="text_1"
                    name="text_1"
                    value={mobileNumberPopup}
                    class="form-control"
                    placeholder="05500000000"
                    maxLength={"10"}
                    required
                    onChange={(e) => setMobileNumberPopup(e.target.value)}
                  />
                </div>
              </div>

              <div class="col-xs-12">
                <div class="form-group">
                  <label class="control-label" for="file_1">
                    <span>{imgVal ? "חשבונית נבחרה" : "צילום חשבונית"}</span>
                    {imgVal ? null : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.045"
                        height="22.787"
                        viewBox="0 0 28.045 22.787"
                      >
                        <g
                          data-name="Group 358"
                          transform="translate(-269.753 -582.108)"
                        >
                          <g
                            data-name="Group 353"
                            transform="translate(294.292 585.614)"
                          >
                            <path
                              fill="#fff"
                              d="M0 0h-3.6l-.732-1.521A3.507 3.507 0 00-7.49-3.506h-6.054A3.506 3.506 0 00-16.7-1.521L-17.434 0h-3.6a3.516 3.516 0 00-3.5 3.506v12.269a3.516 3.516 0 003.5 3.506H0a3.517 3.517 0 003.506-3.506V3.506A3.517 3.517 0 000 0m-10.517 16.652a7.011 7.011 0 01-7.011-7.012 7.011 7.011 0 017.011-7.011A7.011 7.011 0 01-3.506 9.64a7.011 7.011 0 01-7.011 7.012"
                              data-name="Path 177"
                            ></path>
                          </g>
                          <g
                            data-name="Group 354"
                            transform="translate(283.776 590.697)"
                          >
                            <path
                              fill="#fff"
                              d="M0 0a4.562 4.562 0 00-4.557 4.557A4.563 4.563 0 000 9.115a4.563 4.563 0 004.557-4.558A4.562 4.562 0 000 0"
                              data-name="Path 178"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_1"
                    name="file_1[]"
                    value={imgVal}
                    accept=".gif, .jpg, .png"
                    required
                    onChange={(e) => setimgVal(e.target.value)}
                  />
                </div>
              </div>

              <div class="col-xs-12">
                <div class="form-action">
                  <button
                    type="submit"
                    id="button_1"
                    name="button_1"
                    class="btn btn-primary"
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: { event: "reciept_added" },
                      });
                    }}
                  >
                    <span>שליחה</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.167"
                      height="21.168"
                      viewBox="0 0 21.167 21.168"
                    >
                      <g data-name="Group 32" transform="translate(0 10.584)">
                        <path
                          fill="#0057c8"
                          d="M0 0l21.167 10.584v-21.168z"
                          data-name="Path 17"
                        ></path>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <div class="ac-goto-recipe-popup">
            <button
              onClick={() => {
                setPopupRecipe(true);
                setThanksPopup(false);
                TagManager.dataLayer({
                  dataLayer: { event: "no_reciept" },
                });
              }}
              className="ac-btn-recipe"
            >
              ואם אין לי חשבונית כרגע? {">>"}
            </button>
          </div>
        </div>
      ) : null}
      {/* End Upload Image Pop up*/}
      {/* Thanks Pop Up after Upload Image*/}
      {thanksPopup && addImgPopup == false ? <ThanksPopup /> : null}
      {/* End Thanks Pop Up after Upload Image*/}

      {/* Send SMS if dont have a recipe */}
      {popupRecipe ? (
        <div id="ac-recipe-game">
          <div className="ac-inner">
            <div className="ac-close-wrapper">
              <button onClick={() => setPopupRecipe(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.059"
                  height="18.059"
                  viewBox="0 0 18.059 18.059"
                >
                  <g
                    data-name="Group 475"
                    transform="translate(-61.246 -225.649)"
                  >
                    <g
                      data-name="Group 395"
                      transform="translate(64.645 226.231)"
                    >
                      <path
                        fill="#fff"
                        d="M0 0a1.991 1.991 0 00-2.815 0 1.989 1.989 0 000 2.815l14.077 14.078a1.991 1.991 0 102.815-2.816z"
                        data-name="Path 219"
                      ></path>
                    </g>
                    <g
                      data-name="Group 396"
                      transform="translate(61.829 240.309)"
                    >
                      <path
                        fill="#fff"
                        d="M0 0a1.991 1.991 0 002.815 2.815l14.078-14.077a1.991 1.991 0 000-2.815 1.991 1.991 0 00-2.816 0z"
                        data-name="Path 220"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
            <span className="ac-title">
              חסרה לך
              <br />
              חשבונית?
            </span>
            <br />
            <span className="ac-text" style={{ direction: "rtl" }}>
              אפשר להעלות חשבונית
              <br />
              בשווי 25 ₪ על מגוון עוגיות
              <br />
              אוראו עד גמר הפעילות <br />
              ב- 24.11.2022
            </span>
            <span className="ac-title ac-title2">איך?</span>
            <span className="ac-text">
              קליק על הכפתור למטה <br />
              ישלח לך <span className="ac-sms-word-reg">sms</span> <br />
              עם לינק להעלאה
            </span>
            <form onSubmit={handleSubmitSMS}>
              {/* <button className="ac-btn ac-sms-send-btn" type="submit">
                שלחו לי SMS{" "}
              </button> */}
              <button
                type="submit"
                onClick={() => {
                  TagManager.dataLayer({ dataLayer: { event: "sms_reciept" } });
                }}
                className="ac-btn ac-start-game-btn"
              >
                <div className="ac-inner-btn">
                  <span>
                    שלחו <span className="ac-sms-word-bold">SMS</span>
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.167"
                    height="21.168"
                    viewBox="0 0 21.167 21.168"
                  >
                    <g data-name="Group 32" transform="translate(0 10.584)">
                      <path
                        fill="#0057c8"
                        d="M0 0l21.167 10.584v-21.168z"
                        data-name="Path 17"
                      ></path>
                    </g>
                  </svg>
                </div>
              </button>
            </form>

            <span className="ac-text ac-text3">
              חשוב לזכור: אם לא מצרפים <br />
              חשבונית אי אפשר לזכות וחבל
            </span>
          </div>
        </div>
      ) : null}
      {/* End Send SMS if dont have a recipe */}

      {isGotSms ? (
        <div id="ac-recipe-game">
          <div className="ac-inner">
            <div className="ac-close-wrapper">
              <button onClick={() => setIsGotSms(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.059"
                  height="18.059"
                  viewBox="0 0 18.059 18.059"
                >
                  <g
                    data-name="Group 475"
                    transform="translate(-61.246 -225.649)"
                  >
                    <g
                      data-name="Group 395"
                      transform="translate(64.645 226.231)"
                    >
                      <path
                        fill="#fff"
                        d="M0 0a1.991 1.991 0 00-2.815 0 1.989 1.989 0 000 2.815l14.077 14.078a1.991 1.991 0 102.815-2.816z"
                        data-name="Path 219"
                      ></path>
                    </g>
                    <g
                      data-name="Group 396"
                      transform="translate(61.829 240.309)"
                    >
                      <path
                        fill="#fff"
                        d="M0 0a1.991 1.991 0 002.815 2.815l14.078-14.077a1.991 1.991 0 000-2.815 1.991 1.991 0 00-2.816 0z"
                        data-name="Path 220"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
            <span className="ac-title">יש לך את זה?</span>
            <br />

            <span className="ac-text">
              שלחנו לך <span className="ac-sms-word-reg">sms</span> <br />
              עכשיו תורך לקנות ממגוון
              <br />
              עוגיות אוראו ב-₪25 <br />
              ולהעלות חשבונית
            </span>
            <button
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: { event: "got_sms_reciept" },
                });
                window.location.replace("https://oreo.activated.digital/");
              }}
              className="ac-btn ac-start-game-btn"
            >
              <div className="ac-inner-btn">
                <span>קבלתי, תודה</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.167"
                  height="21.168"
                  viewBox="0 0 21.167 21.168"
                >
                  <g data-name="Group 32" transform="translate(0 10.584)">
                    <path
                      fill="#0057c8"
                      d="M0 0l21.167 10.584v-21.168z"
                      data-name="Path 17"
                    ></path>
                  </g>
                </svg>
              </div>
            </button>
            <button className="ac-btn ac-sendsns-again">
              לא קבלתי, שלחו שוב
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FormFinal;
