import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/Group3.png";
import Img from "../assets/img/img.png";
import "../assets/css/GoalsPage.css";
import GamePage from "./GamePage";
import TagManager from "react-gtm-module";

export const GoalsPage = (props) => {
  return (
    <div className="ac-goals-page">
      <div className="ac-container">
        {/* <div className="bread">
          <Link
            to="/"
            onClick={() => {
              window.location.replace("https://oreo.activated.digital/");
              TagManager.dataLayer({ dataLayer: { event: "homepage" } });
            }}
          >
            בית{" "}
          </Link>{" "}
          {" > "}
          <Link
            to="/SharePage"
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "true_friend" } });
            }}
          >
            חבר אמיתי משתף
          </Link>
        </div> */}
        <div className="ac-img-wrapper">
          <img src={logo} alt="Logo" className="ac-img-responive" />
        </div>

        {/* <span class="ac-sharepage-text">
          בעוגיית אוראו לא משתפים חברים,
          <br />
          <strong>
            במשחק אוראו שאפשר
            <br />
            לזכות בו במיני קופר <br />
            משתפים במקסימום
          </strong>
        </span> */}
        <span class="ac-goalspage-textplace">מקום 1:</span>
        <span class="ac-goalspage-text">מיני קופר</span>
        <span class="ac-goalspage-textplace">מקום 2 עד 20:</span>
        <span class="ac-goalspage-text">כרטיס זוגי לקולנוע</span>
        <Link
          to="/"
          onClick={() => {
            // window.location.replace("https://oreo.activated.digital/");

            TagManager.dataLayer({ dataLayer: { event: "lets_start" } });
          }}
          className="ac-btn ac-start-game-btn"
        >
          <div className="ac-inner-btn">
            <span>למשחק</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link>
        <div className="ac-img-wrapper">
          <img src={Img} alt="illustration" className="ac-img-responive" />
        </div>
      </div>
    </div>
  );
};

export default GoalsPage;
