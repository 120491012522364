import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FormFinal from "../Components/FormFinal";
import logo from "../assets/img/findoreo@2x.png";

import "../assets/css/FormPage.css";

export const FormPage = () => {
  const location = useLocation();
  // Changed on 15/08/2023
  // if (location?.time === undefined) {
  //   window.location.replace("https://oreo.activated.digital/");
  // }
  useEffect(() => {});
  return (
    <div className="ac-form-page">
      {/* <div className="ac-img-wrapper">
        <img src={logo} alt="Logo" className="ac-img-responive" />
      </div> */}

      <div className="ac_help_center">
        <span className="ac-page-title">שיחקת אותה!</span>
        <p>
          מצאת ת׳אוראו במולטיוורס
          <br />
          ב-<strong>{location?.time}</strong>
        </p>
        <span class="ac-light-text">
          כדי שנוכל ליצור איתך קשר במקרה של זכייה
          <br />
          נצטרך מעט פרטים וצילום חשבונית:
        </span>

        {/* <button
          onClick={() =>
            window.location.replace("https://oreo.activated.digital/")
          }
          className="ac-btn"
        >
          {" "}
          בא לי לשחק שוב{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.099"
            height="24.775"
            viewBox="0 0 14.099 24.775"
          >
            <defs></defs>
            <path
              className="a"
              d="M0,0A1.623,1.623,0,0,1-1.146-.472L-11.991-11.233a1.625,1.625,0,0,1-.481-1.155,1.625,1.625,0,0,1,.481-1.154L-1.146-24.3a1.627,1.627,0,0,1,2.3.009,1.626,1.626,0,0,1-.009,2.3l-9.682,9.606L1.146-2.782a1.627,1.627,0,0,1,.009,2.3A1.621,1.621,0,0,1,0,0"
              transform="translate(12.472 24.775)"
            />
          </svg>
        </button> */}
      </div>
      <FormFinal
        time={location?.time}
        utmVal={location?.utm_val}
        utmSource={location?.utmSource}
        utmMedium={location?.utmMedium}
        isDev={location?.isDev}
        rot_step1={location?.rot_step1}
        rot_step2={location?.rot_step2}
        rot_step3={location?.rot_step3}
        rot_step4={location?.rot_step4}
        serverStart={location?.serverStart}
        activatedStart={location?.activatedStart}
        serverEndClock={location?.serverEndClock}
      />
    </div>
  );
};

export default FormPage;
